import React from 'react'
import styles from './PostSnippet.module.css'
import Link from '../../components/Link'

export default ({ path, name, thumb, description}) => {
  return (
    <div className={styles['PostSnippet']}>
      <Link to={path} className={styles['PostSnippet__Thumb']}>
        <img className={styles['PostSnippet__ThumbImage']} src={thumb && thumb.publicURL} alt={name} />
      </Link>
      <div className={styles['PostSnippet__Content']}>
        <h3 className={styles['PostSnippet__Title']}>
          <Link to={path}>{name}</Link>
        </h3>
        <p className={styles['PostSnippet__Descripton']}>{description}</p>
        <Link to={path}>Читать полностью</Link>
      </div>
    </div>
  )
}
