import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/Layout.jsx'
import LayoutWithSideMenu from '../components/LayoutWithSideMenu'
import PostList from '../components/PostList'
import Container from '../components/Container'

export default ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map(edge => edge.node.frontmatter)
  const breadcrumbs = [
    {
      name: 'Главная',
      path: '/'
    },
    {
      name: 'Статьи и подборки',
      path: '/blog'
    }
  ]
  return (
    <Layout>
      <Helmet>
        <title>Блог компании АлексКомп</title>
        <meta name="description" content="Блог компании АлексКомп" />
      </Helmet>
      <Container>
        <LayoutWithSideMenu heading="Статьи и подборки" breadcrumbs={breadcrumbs}>
          <PostList posts={posts} />
        </LayoutWithSideMenu>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query getBlogPosts {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } },
      sort: {order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          frontmatter {
            name
            path
            description
            thumb {
              publicURL
            }
          }
        }
      }
    }
  }
`;
